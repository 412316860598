/** @jsx jsx */
import { jsx } from "theme-ui"
import { PageProps } from "gatsby"

import Layout from "components/layout"
import SEO from "components/seo"
import Calculator from "components/calculator"
import RelatedContent from "components/related-content"
import { getLocationState, LocationState } from "components/state-link"
import { Location as HLocation } from "history"

const Page = ({ location }: PageProps) => {
  const title = `Ипотечный калькулятор с досрочным погашением`
  const description = `Ипотечный калькулятор рассчитывает кредит с досрочным погашением. Таблица и график ипотечных платежей. Учитываем особенности банков в ${new Date().getFullYear()} г.`
  const locationState = getLocationState((location as any) as HLocation<LocationState>)

  return (
    <Layout locationState={locationState}>
      <SEO title={title} description={description} pathname={location.pathname} />
      <h1 id="calculator">Калькулятор досрочного погашения ипотеки</h1>
      <div>
        <hr />
      </div>
      <Calculator needPrepayments location={location} />
      <div id="content" sx={{ mt: 3 }}>
        <hr />
        <h2 id="как-рассчитывается-досрочное-погашение">Как рассчитывается досрочное погашение</h2>
        Существует два вида досрочного погашения: с уменьшением срока кредита и с уменьшением основного долга. Для
        второго варианта кредит рассчитывается по стандартным формулам, а в момент досрочного погашения сумма основного
        долга по ипотеке уменьшается на сумму досрочного погашения.
        <h2 id="есть-ли-смысл-досрочного-погашать-ипотеку">Есть ли смысл досрочного погашать ипотеку</h2>
        Чтобы ответить на этот вопрос, надо для начала посчитать выгоду от вложения денег в банковский депозит или
        другой способ инвестирования. Данную выгоду следует сравнить с выгодой от досрочного погашения ипотеки.
      </div>
      <RelatedContent locationState={locationState} />
    </Layout>
  )
}

export default Page
